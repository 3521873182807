import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Banner from "./Banner";
import * as styles from "./OverlayedImagesBanner.module.css";

type OverlayedImagesBannerProps = {
  children?: React.ReactNode;
  bg?: JSX.Element;
  backgroundImg?: string;
  height?: string;
};

const OverlayedImagesBanner = ({
  backgroundImg,
  height,
  children,
  bg,
}: OverlayedImagesBannerProps) => {
  // MAYBE: styling is static and might need some work

  const style = height ? { height: height } : {};

  let bgElement: React.ReactNode;
  if (bg) {
    bgElement = React.cloneElement(bg, {
      className: styles.bg,
    });
  } else if (backgroundImg) {
    bgElement = <img src={backgroundImg} className={styles.bg} />;
  } else {
    bgElement = <div></div>;
  }

  const bannerBgElement = (
    <div>
      <div className={styles.container} style={style}>
        <StaticImage
          src="../../../assets/overlay.png"
          alt=""
          className={styles.overlay}
          imgStyle={{
            maxHeight: "100%",
          }}
        />
        {bgElement}
      </div>
    </div>
  );

  return <Banner bg={bannerBgElement}>{children}</Banner>;
};

export default OverlayedImagesBanner;
