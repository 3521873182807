import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Banner from "./banners/Banner";

import * as styles from "./Categories.module.css";
import StyledLink from "./StyledLink";

type CategoriesProps = {};

const Categories = ({}: CategoriesProps) => {
  const bannerStyle: React.CSSProperties = {
    height: "100px",
    marginTop: "-100px",
    zIndex: 1,
  };

  return (
    <nav className={styles.container}>
      <h1>Categorie</h1>
      <h3>La protezione ti accompagna ovunque</h3>

      <div className={styles.cards}>
        <Link to="/prodotti/casa" className={styles.card}>
          <StaticImage
            src="../../assets/casa.png"
            alt=""
            style={{ zIndex: -1 }}
          />
          <Banner
            bg={<StaticImage src="../../assets/bokeh.jpg" alt="" />}
            bgStyle={{ opacity: "50%", borderRadius: "0 0 0 50px" }}
            style={bannerStyle}
          >
            <div className={styles.cardContent}>
              <h2>Casa</h2>
              <p>Un ambiente pulito è un ambiente felice</p>
            </div>
          </Banner>
        </Link>

        <Link to="/prodotti/bucato" className={styles.card}>
          <StaticImage
            src="../../assets/bucato.png"
            alt=""
            style={{ zIndex: -1 }}
          />
          <Banner
            bg={<StaticImage src="../../assets/bokeh.jpg" alt="" />}
            bgStyle={{ opacity: "50%" }}
            style={bannerStyle}
          >
            <div className={styles.cardContent}>
              <h2>Bucato</h2>
              <p>Il vero pulito si riconosce a pelle</p>
            </div>
          </Banner>
        </Link>

        <Link to="/prodotti/persona" className={styles.card}>
          <StaticImage
            src="../../assets/persona.png"
            alt=""
            style={{ zIndex: -1 }}
          />
          <Banner
            bg={<StaticImage src="../../assets/bokeh.jpg" alt="" />}
            bgStyle={{ opacity: "50%", borderRadius: "0 0 50px 0" }}
            style={bannerStyle}
          >
            <div className={styles.cardContent}>
              <h2>Persona</h2>
              <p>Il tocco delicato di una protezione profonda</p>
            </div>
          </Banner>
        </Link>
      </div>

      <StyledLink to="/prodotti">Scopri tutti i prodotti</StyledLink>
    </nav>
  );
};

export default Categories;
