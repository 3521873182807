import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import StyledLink from "../StyledLink";
import OverlayedImagesBanner from "./OverlayedImagesBanner";
import * as styles from "./HomeBanner.module.css";

const HomeBanner = () => {
  const bg = (
    <StaticImage
      src="../../../assets/home_banner.png"
      alt=""
      placeholder="blurred"
    />
  );

  return (
    <OverlayedImagesBanner bg={bg} height="500px">
      <div className={styles.bannerContentContainer}>
        <div className={styles.bannerContent}>
          <h2>Un mondo di protezione</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <StyledLink to="/404">???</StyledLink>
        </div>
      </div>
    </OverlayedImagesBanner>
  );
};

export default HomeBanner;
