import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import StyledLink from "../StyledLink";
import Banner from "./Banner";
import * as styles from "./AmazonBanner.module.css";

const AmazonBanner = () => {
  const bg = (
    <StaticImage
      src="../../../assets/amazon_banner.png"
      alt="Amazon banner"
      placeholder="blurred"
    />
  );

  return (
    <Banner bg={bg}>
      <div className={styles.bannerContentContainer}>
        <div className={styles.bannerContent}>
          <h2>La protezione è di casa</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>

          <StyledLink to="/404">Scopri di più</StyledLink>
        </div>
      </div>
    </Banner>
  );
};

export default AmazonBanner;
