import * as React from "react";

import Layout from "../components/layout/Layout";
import HomeBanner from "../components/ui/banners/HomeBanner";
import Seo from "../components/seo";
import AmazonBanner from "../components/ui/banners/AmazonBanner";
import ContactsBanner from "../components/ui/banners/ContactsBanner";
import Categories from "../components/ui/Categories";

const Index = () => {
  return (
    <>
      <Seo title="Home" description="Lorem ipsum dolor sit amet" />
      <Layout>
        <HomeBanner />
        <Categories />
        <AmazonBanner />
        <ContactsBanner />
      </Layout>
    </>
  );
};

export default Index;
